import React from "react"
import { Link as GatsbyLink, StaticQuery, graphql } from "gatsby"
// import { MDXRenderer } from "gatsby-plugin-mdx"
// import { MDXProvider } from "@mdx-js/react"
import ReactMarkdown from "react-markdown"
import { withStyles, makeStyles } from "@material-ui/core/styles"
import {
  Toolbar,
  Typography,
  Box,
  Container,
  Divider,
  Breadcrumbs,
  Link,
} from "@material-ui/core"
import rehypeRaw from "rehype-raw"

import downloadIcon from "../images/download.svg"
import Layout from "../components/layout"

export const query = graphql`
  query DownloadQuery($id: Int!) {
    strapiDownload(strapiId: { eq: $id }) {
      title
      filetype {
        description
        name
        files {
          url
          name
        }
      }
    }
  }
`

const StyledToolbar = withStyles({
  root: {
    background: "#031242",
  },
})(Toolbar)

function Download({ data }) {
  const classes = useStyles()
  const download = data.strapiDownload
  return (
    <Layout siteTitle={download.title}>
      <StyledToolbar />

      <Container>
        <Box pt={5} pb={3}>
          <Box display="flex" justifyContent="flex-end">
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" component={GatsbyLink} to="/">
                Home
              </Link>
              <Link color="inherit" component={GatsbyLink} to="/downloads/">
                Downloads
              </Link>
              <Typography color="textPrimary">
                {download.title.substring(0, 20).concat("...")}
              </Typography>
            </Breadcrumbs>
          </Box>
        </Box>
      </Container>

      <Container maxWidth="md">
        <Box pb={3}>
          <Typography variant="h4" gutterBottom color="primary" align="center">
            <b>{download.title}</b>
          </Typography>
        </Box>
        <Box pb={8}>
          {download.filetype.map(item => {
            return (
              <Box p={2}>
                <Box>
                  <Typography variant="subtitle1">
                    <b>{item.name}</b>
                  </Typography>
                </Box>
                <Box>
                  <ReactMarkdown
                    rehypePlugins={[rehypeRaw]}
                    children={item.description}
                    transformImageUri={uri =>
                      uri.startsWith("http")
                        ? uri
                        : `${process.env.GATSBY_IMAGE_BASE_URL}${uri}`
                    }
                    className="dynamicContent"
                    escapeHtml={false}
                  />
                </Box>
                {item.files.map(items => {
                  return (
                    <Box display="flex">
                      <img
                        src={downloadIcon}
                        height="18px"
                        width="18px"
                        alt="download file"
                      />
                      <Box pl={1}>
                        <a
                          href={`${process.env.GATSBY_IMAGE_BASE_URL}${items.url}`}
                          download
                          className={classes.link}
                        >
                          <Typography variant="body2">{items.name}</Typography>
                        </a>
                      </Box>
                    </Box>
                  )
                })}
                <Box pt={4}>
                  <Divider />
                </Box>
              </Box>
            )
          })}
        </Box>
      </Container>
    </Layout>
  )
}

const useStyles = makeStyles(theme => ({
  link: {
    textDecoration: "none",
    color: "black",
  },
  cardMedia: {
    height: "100%",
    paddingTop: "56.25%",
  },
}))

export default Download
